import React, { useState } from 'react';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { makeAuthenticatedApiCall } from '../../apiHelper';
import { format, parse } from 'date-fns'; // Importer parse pour interpréter les dates
import jszip from 'jszip';
import { saveAs } from 'file-saver';
import theme from '../../theme';

const DownloadInvoices: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Fonction pour formater une date au format 'DD/MM/YYYY'
  const formatDate = (date: Date | null) => {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy');
  };

  // Fonction pour parser une date au format 'DD/MM/YYYY'
  const parseDate = (dateString: string) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`); // Convertit en format ISO pour le DatePicker
  };

  const handleDownload = async () => {
    if (!startDate || !endDate) {
      return alert('Please select both start and end dates.');
    }

    const formattedStartDate = format(startDate, 'yyyy-MM-dd');
    const formattedEndDate = format(endDate, 'yyyy-MM-dd');

    try {
      setLoading(true); // Activer le loader
      const response = await makeAuthenticatedApiCall(
        'get',
        `/api/qonto/invoices/download?startDate=${formattedStartDate}&endDate=${formattedEndDate}`,
      );

      const betterFormattedStartDate = format(startDate, 'dd-MM-yyyy');
      const betterFormattedEndDate = format(endDate, 'dd-MM-yyyy');

      const zip = new jszip();
      const folder = zip.folder(`Factures_${betterFormattedStartDate}_au_${betterFormattedEndDate}`); // Correction du nom du zip

      const invoiceUrls = (response.data as any).data.invoices; // On suppose que vous avez également les informations sur les factures

      // Téléchargement de chaque fichier
      const promises = invoiceUrls.map(async (invoice: { url: string; number: string }) => {
        try {
          const fileResponse = await fetch(invoice.url);
          const blob = await fileResponse.blob();
          const fileName = `${invoice.number}.pdf`; // Utilise invoice.number pour le nom du fichier
          folder?.file(fileName, blob); // Ajouter le fichier au dossier zip
        } catch (error) {
          console.error('Error downloading invoice:', error);
        }
      });

      await Promise.all(promises); // Attendre que tous les fichiers soient téléchargés

      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, `Factures_${betterFormattedStartDate}_au_${betterFormattedEndDate}.zip`); // Télécharger le zip final
    } catch (error) {
      console.error('Error downloading invoices:', error);
      alert('Failed to download invoices.');
    } finally {
      setLoading(false); // Désactiver le loader
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginLeft: 1, marginTop: 4 }}>
        <DatePicker
          sx={{ width: '210px' }}
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          className="inputElement"
          renderInput={(params) => <TextField {...params} className="inputElement" variant="outlined" fullWidth />}
          format="dd/MM/yyyy" // Format d'affichage
          mask="__/__/____" // Masque pour aider à l'insertion
        />
        <DatePicker
          sx={{ width: '210px' }}
          label="End Date"
          value={endDate}
          className="inputElement"
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(params) => <TextField {...params} className="inputElement" variant="outlined" fullWidth />}
          format="dd/MM/yyyy" // Format d'affichage
          mask="__/__/____" // Masque pour aider à l'insertion
        />
        <Button
          sx={{
            marginLeft: 1,
            fontWeight: 900,
            fontSize: 12,
            backgroundColor: theme.palette.saffron.main,
            '&:hover': { backgroundColor: theme.palette.saffron.main },
          }}
          variant="contained"
          onClick={handleDownload}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Factures Biwiz'}
        </Button>
      </Box>
    </LocalizationProvider>
  );
};

export default DownloadInvoices;
