import { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  TextField,
  CardContent,
  Card,
  CardHeader,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip as MuiTooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/AuthContext';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { makeAuthenticatedApiCall } from '../apiHelper';
import { format, startOfYear, startOfMonth, endOfMonth } from 'date-fns';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  useArbitrationPerformance,
  useBuyingStatistics,
  useFlopTransformations,
  useGetAgencies,
  useGetFranchise,
  useSellingStatistics,
  useStatisticsCountByDate,
  useTopAgencies,
  useTopTransformations,
} from '../hooks/useApiQueries';
import { capitalizeFirstLetter, priceFormatter } from '../utils';
import StockOverview from '../components/Statistics/StockOverview';
import { AgencyPerformance } from '../components/Statistics/AgencyPerformance';
import AgencyStatsOverview from '../components/Statistics/AgencyStatsOverview';
import MonthlyStatsOverview from '../components/Statistics/MonthlyStatsOverview';
import DailyBuyingStatsTable from '../components/Statistics/DailyBuyingStatsTable';
import DailySellingStatsTable from '../components/Statistics/DailySellingStatsTable';

// Register ChartJS components
ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const HomePage = () => {
  const [selectedFranchise, setSelectedFranchise] = useState<string>('');
  const [selectedAgency, setSelectedAgency] = useState<string>(''); // New state for selected agency
  const auth = useAuth();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const currentUser = auth ? auth.currentUser : null;
  const userNetwork = currentUser?.data?.data?.network;
  const idPipedrive = currentUser?.data?.data?.idPipedrive;
  console.log(idPipedrive);

  const { data: franchiseData } = useGetFranchise();
  const { data: agenciesData } = useGetAgencies();

  // Initialiser startDate et endDate avec les valeurs par défaut
  const [startDate, setStartDate] = useState<Date>(startOfYear(new Date()));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>(null);

  const isDisabled = userNetwork === 'Agence Automobiliere' && idPipedrive !== '197';

  const { data: topArbitrations } = useArbitrationPerformance('top', startDate, endDate, selectedFranchise);
  const { data: flopArbitrations } = useArbitrationPerformance('flop', startDate, endDate, selectedFranchise);

  const { data: topAgencies } = useTopAgencies(startDate, endDate, selectedFranchise);

  const { data: dataCountByDate, refetch: refetchCountByDate } = useStatisticsCountByDate(
    startOfMonth(startDate),
    endOfMonth(startDate),
    selectedFranchise,
    selectedAgency,
  );

  const { data: dataBuying, refetch: refetchBuying } = useBuyingStatistics(
    startDate,
    endDate,
    selectedFranchise,
    selectedAgency,
  );
  const { data: dataSelling, refetch: refetchSelling } = useSellingStatistics(
    startDate,
    endDate,
    selectedFranchise,
    selectedAgency,
  );

  const { data: flopTransformations, isLoading: flopTransformationsLoading } = useFlopTransformations(
    startDate,
    endDate,
    selectedFranchise,
  );

  const { data: topTransformations, isLoading: topTransformationsLoading } = useTopTransformations(
    startDate,
    endDate,
    selectedFranchise,
  );

  // New state for daily additional statistics
  const [dailyAdditionalStats, setDailyAdditionalStats] = useState<any[]>([]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleFranchiseChange = (value: string) => {
    setSelectedFranchise(value);
    setSelectedAgency(''); // Reset agency selection when franchise changes
  };

  useEffect(() => {
    const fetchAdditionalStatistics = async () => {
      if (!startDate || !endDate) {
        console.error('Start date and end date must be defined');
        return;
      }

      try {
        const formattedStartDate = format(startDate, 'yyyy-MM-dd');
        const formattedEndDate = format(endDate, 'yyyy-MM-dd');
        const franchise = selectedFranchise;
        const agency = selectedAgency;

        const [
          doubleCotationsResponse,
          processedArbitrationsResponse,
          acceptedArbitrationsResponse,
          awaitingArbitrationsResponse,
        ] = await Promise.all([
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/count?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/processed?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/accepted?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
          makeAuthenticatedApiCall(
            'get',
            `/api/statistics/arbitrations/awaiting?startDate=${formattedStartDate}&endDate=${formattedEndDate}&franchise=${encodeURIComponent(franchise)}&org_name=${encodeURIComponent(agency)}`,
          ),
        ]);

        // Aggregate data by date
        const aggregatedData: Record<string, any> = {};

        const aggregateResponse = (response: any, key: string) => {
          response.data.forEach((item: any) => {
            const date = format(new Date(item.cotation_date || item.arbitration_date), 'dd/MM/yyyy');
            if (!aggregatedData[date]) {
              aggregatedData[date] = {
                date,
                doubleCotations: 0,
                processedArbitrations: 0,
                acceptedArbitrations: 0,
                awaitingArbitrations: 0,
              };
            }
            aggregatedData[date][key] += item.cotation_count || item.arbitration_count;
          });
        };

        aggregateResponse(doubleCotationsResponse, 'doubleCotations');
        aggregateResponse(processedArbitrationsResponse, 'processedArbitrations');
        aggregateResponse(acceptedArbitrationsResponse, 'acceptedArbitrations');
        aggregateResponse(awaitingArbitrationsResponse, 'awaitingArbitrations');

        setDailyAdditionalStats(Object.values(aggregatedData));
      } catch (error) {
        console.error('Error fetching additional statistics:', error);
      }
    };

    fetchAdditionalStatistics();
  }, [startDate, endDate, selectedFranchise, selectedAgency]);

  // Configuration des options du graphique
  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const label = context.dataset.label || '';
            return `${label}: ${context.raw}`;
          },
        },
      },
    },
  };

  const totalDoubleCotations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.doubleCotations, 0);
  const totalProcessedArbitrations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.processedArbitrations, 0);
  const totalAcceptedArbitrations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.acceptedArbitrations, 0);
  const totalAwaitingArbitrations = dailyAdditionalStats.reduce((sum, stat) => sum + stat.awaitingArbitrations, 0);

  if (currentUser?.data?.data?.role !== 'agency') {
    return (
      <Container maxWidth={false} sx={{ padding: theme.spacing(4), backgroundColor: theme.palette.background.default }}>
        <Typography variant="body1" fontSize="30px" gutterBottom textAlign="center" marginBottom={theme.spacing(4)}>
          Tableau de bord
        </Typography>

        <AgencyStatsOverview />

        <Box sx={{ marginTop: theme.spacing(8), width: '100%', maxWidth: 1200, mx: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FormControl variant="outlined" sx={{ minWidth: 250 }}>
              <InputLabel className="inputElement" id="franchise-select-label">
                Franchise
              </InputLabel>
              <Select
                labelId="franchise-select-label"
                id="franchise-select"
                className="inputElement"
                value={selectedFranchise}
                onChange={(e) => handleFranchiseChange(e.target.value)}
                label="Franchise"
              >
                <MenuItem value="">
                  <em>Aucune sélection</em>
                </MenuItem>
                {franchiseData?.map((franchise: any) => (
                  <MenuItem key={franchise.franchise} value={franchise.franchise}>
                    {franchise.franchise}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ minWidth: 250 }}>
              <InputLabel className="inputElement" id="agency-select-label">
                Agence
              </InputLabel>
              <Select
                labelId="agency-select-label"
                id="agency-select"
                className="inputElement"
                value={selectedAgency}
                onChange={(e) => setSelectedAgency(e.target.value)}
                label="Agence"
              >
                <MenuItem value="">
                  <em>Aucune sélection</em>
                </MenuItem>
                {(agenciesData as any[])
                  ?.filter((agency: any) => agency.franchise === selectedFranchise)
                  .map((agency: any) => (
                    <MenuItem key={agency.org_name} value={agency.org_name}>
                      {agency.org_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                <DatePicker
                  className="inputElement"
                  label="Date de début"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="JJ/MM/AAAA"
                      inputProps={{
                        ...params.inputProps,
                        mask: '__/__/____',
                      }}
                    />
                  )}
                  format="dd/MM/yyyy"
                />
                <DatePicker
                  label="Date de fin"
                  className="inputElement"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      placeholder="JJ/MM/AAAA"
                      inputProps={{
                        ...params.inputProps,
                        mask: '__/__/____',
                      }}
                    />
                  )}
                  format="dd/MM/yyyy"
                />
              </Box>
            </LocalizationProvider>
          </Box>

          {/* Stock Overview Component */}
          <StockOverview
            startDate={startDate}
            endDate={endDate}
            franchise={selectedFranchise}
            orgName={selectedAgency}
          />

          <Grid container spacing={4} sx={{ marginTop: theme.spacing(4) }}>
            <Grid xs={12}>
              <Typography variant="body1" gutterBottom align="center">
                Transformations
              </Typography>
            </Grid>
            {!flopTransformationsLoading && flopTransformations && (
              <Grid item xs={12} md={6}>
                <AgencyPerformance data={flopTransformations} title="Flop 5 Transformations" />
              </Grid>
            )}
            {!topTransformationsLoading && topTransformations && (
              <Grid item xs={12} md={6}>
                <AgencyPerformance data={topTransformations} title="Top 5 Transformations" />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={4} sx={{ marginTop: theme.spacing(6) }}>
            <Grid xs={12}>
              <Typography variant="body1" gutterBottom align="center">
                Les Top
              </Typography>
            </Grid>
            {topAgencies &&
              Object.entries(topAgencies).map(([category, agencies], index) => (
                <Grid item xs={12} sm={4} key={category} sx={index === 0 ? { paddingLeft: '0 !important' } : {}}>
                  <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
                    <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.saffron.main }}>
                      {`${t(category.charAt(0).toUpperCase() + category.slice(1))}`}
                    </Typography>
                    <CardContent>
                      <Grid container spacing={2} direction="column">
                        {agencies.map((agency: any, index: number) => (
                          <Grid container item xs={12} key={agency.org_name} justifyContent="space-between">
                            <Typography
                              variant="body2"
                              sx={{ fontSize: '14px', fontWeight: 600, color: theme.palette.black.main }}
                            >
                              {capitalizeFirstLetter(agency.org_name)}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: '14px', fontWeight: 600, color: theme.palette.black.light }}
                            >
                              {agency.count}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
          </Grid>

          {/* Top Agencies Section */}
          <Grid
            container
            spacing={4}
            sx={{
              marginTop: theme.spacing(4),
              width: '100%',
              maxWidth: 1200,
              mx: 'auto',
              marginBottom: theme.spacing(1),
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography variant="body1" gutterBottom align="center" sx={{ marginBottom: theme.spacing(4) }}>
                Statistiques des cotations sur le mois en cours
              </Typography>
              <MonthlyStatsOverview franchise={selectedFranchise} orgName={selectedAgency} />
            </Box>
          </Grid>

          <Grid
            container
            spacing={4}
            sx={{
              marginTop: theme.spacing(0),
              marginBottom: theme.spacing(0),
              width: '100%',
              maxWidth: 1200,
              mx: 'auto',
            }}
          >
            <Box sx={{ width: '100%', display: 'none' }}>
              <Typography variant="body1" gutterBottom>
                Statistiques des arbitrages sur la période
              </Typography>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item xs={12} sm={6} md={3}>
                  <MuiTooltip
                    title="Cette requête compte le nombre de fois où on a cliqué sur le bouton 'Double-cotations' dans la période sélectionnée."
                    arrow
                    placement="top"
                  >
                    <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                      <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        Doubles-cotations
                      </Typography>
                      <Typography variant="h5" fontWeight="bold">
                        {totalDoubleCotations}
                      </Typography>
                      <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                        <b>-2.3%</b> vs last period (fausse stat)
                      </Typography>
                    </Card>
                  </MuiTooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MuiTooltip
                    title="Cette requête compte le nombre de cas d'arbitrage traités dans la période sélectionnée."
                    arrow
                    placement="top"
                  >
                    <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                      <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        Arbitrages traités
                      </Typography>
                      <Typography variant="h5" fontWeight="bold">
                        {totalProcessedArbitrations}
                      </Typography>
                      <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                        <b>-2.3%</b> vs last period (fausse stat)
                      </Typography>
                    </Card>
                  </MuiTooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MuiTooltip
                    title="Cette requête compte le nombre de cas d'arbitrage acceptés par une agence dans la période sélectionnée."
                    arrow
                    placement="top"
                  >
                    <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                      <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        Arbitrages acceptés
                      </Typography>
                      <Typography variant="h5" fontWeight="bold">
                        {totalAcceptedArbitrations}
                      </Typography>
                      <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                        <b>-2.3%</b> vs last period (fausse stat)
                      </Typography>
                    </Card>
                  </MuiTooltip>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <MuiTooltip
                    title="Cette requête compte le nombre de cas d'arbitrage en attente d'action dans la période sélectionnée."
                    arrow
                    placement="top"
                  >
                    <Card variant="outlined" sx={{ padding: theme.spacing(2), textAlign: 'left' }}>
                      <Typography variant="body1" sx={{ fontSize: '14px' }}>
                        Arbitrages en attente
                      </Typography>
                      <Typography variant="h5" fontWeight="bold">
                        {totalAwaitingArbitrations}
                      </Typography>
                      <Typography variant="body2" color="red" sx={{ fontSize: '14px', color: theme.palette.red.main }}>
                        <b>-2.3%</b> vs last period (fausse stat)
                      </Typography>
                    </Card>
                  </MuiTooltip>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={4}
                sx={{
                  marginTop: theme.spacing(1),
                  width: '100%',
                  maxWidth: 1200,
                  mx: 'auto',
                  marginBottom: theme.spacing(4),
                }}
              >
                {topArbitrations && (
                  <Grid item xs={12} sm={6}>
                    <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
                      <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.saffron.main }}>
                        Top 5 Arbitrages
                      </Typography>
                      <CardContent>
                        <Grid container spacing={2} direction="column">
                          {topArbitrations.map((arbitration: any, index: number) => (
                            <Grid container item xs={12} key={arbitration.org_name} justifyContent="space-between">
                              <Typography variant="body2" sx={{ color: theme.palette.black.main }}>
                                {capitalizeFirstLetter(arbitration.org_name)}
                              </Typography>
                              <Typography variant="body1" sx={{ color: theme.palette.black.light }}>
                                {arbitration.count}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
                {flopArbitrations && (
                  <Grid item xs={12} sm={6}>
                    <Card elevation={5} sx={{ padding: theme.spacing(2) }}>
                      <Typography variant="body1" sx={{ textAlign: 'left', color: theme.palette.red.main }}>
                        Flop 5 Arbitrages
                      </Typography>
                      <CardContent>
                        <Grid container spacing={2} direction="column">
                          {flopArbitrations.map((arbitration: any, index: number) => (
                            <Grid container item xs={12} key={arbitration.org_name} justifyContent="space-between">
                              <Typography variant="body2" sx={{ color: theme.palette.black.main }}>
                                {capitalizeFirstLetter(arbitration.org_name)}
                              </Typography>
                              <Typography variant="body1" sx={{ color: theme.palette.black.light }}>
                                {arbitration.count}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid
            container
            spacing={4}
            sx={{
              marginTop: theme.spacing(0),
              width: '100%',
              maxWidth: 1200,
              mx: 'auto',
              marginBottom: theme.spacing(2),
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography variant="body1" gutterBottom align="center" sx={{ marginBottom: theme.spacing(1) }}>
                Statistiques au jour le jour
              </Typography>
            </Box>
            <Box sx={{ width: '100%', maxWidth: 1200, mx: 'auto' }}>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Card sx={{ marginTop: theme.spacing(5) }} elevation={5}>
                    <CardHeader title="Achats" sx={{ textAlign: 'center' }} />
                    <CardContent>
                      <DailyBuyingStatsTable franchise={selectedFranchise} orgName={selectedAgency} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card sx={{ marginTop: theme.spacing(5) }} elevation={5}>
                    <CardHeader title="Ventes" align="center" />
                    <CardContent>
                      <DailySellingStatsTable franchise={selectedFranchise} orgName={selectedAgency} />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} display="none">
                  <Card sx={{ marginTop: theme.spacing(5) }} elevation={5}>
                    <CardHeader title="Statistiques des arbitrages" sx={{ textAlign: 'center' }} />
                    <CardContent>
                      <TableContainer component={Paper} sx={{ marginTop: 0 }}>
                        <Table>
                          <TableHead>
                            <TableRow sx={{ background: '#262626' }}>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Date</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Double Cotations</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Arbitrages Traités</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Arbitrages Acceptés</TableCell>
                              <TableCell sx={{ fontWeight: 600, color: 'white' }}>Arbitrages en Attente</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dailyAdditionalStats.map((stat) => (
                              <TableRow key={stat.date}>
                                <TableCell>{stat.date}</TableCell>
                                <TableCell>{stat.doubleCotations}</TableCell>
                                <TableCell>{stat.processedArbitrations}</TableCell>
                                <TableCell>{stat.acceptedArbitrations}</TableCell>
                                <TableCell>{stat.awaitingArbitrations}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Box sx={{ width: '100%', maxWidth: 1000, mx: 'auto' }}>
            <Card elevation={10}>
              {loading ? (
                <CircularProgress sx={{ alignSelf: 'center' }} />
              ) : (
                chartData && <Line data={chartData} options={options} />
              )}
            </Card>
          </Box>
        </Box>
      </Container>
    );
  } else {
    return (
      <>
        <Container maxWidth={false} className="homeSplash">
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Typography variant="h1">Bienvenue</Typography>
            <Typography variant="body1" color="white">
              {currentUser?.data?.organization?.name}
            </Typography>
            <Grid container direction={isMobile ? 'column' : 'row'} justifyContent="space-evenly" alignItems="center">
              <Box
                className="linkBox"
                style={{ marginBottom: isMobile ? theme.spacing(2) : 0, cursor: 'pointer' }}
                onClick={() => handleNavigation('/deals-list')}
              >
                Consulter la liste des cotations
              </Box>
              <Box
                className="linkBox"
                style={{ marginBottom: isMobile ? theme.spacing(2) : 0, cursor: 'pointer' }}
                onClick={() => handleNavigation('/cotation')}
              >
                Faire une demande de cotation
              </Box>
              {!isDisabled && (
                <Box
                  className="linkBox"
                  style={{
                    flexDirection: 'column',
                    padding: '2.2em 0',
                    justifyContent: 'center',
                    marginBottom: isMobile ? theme.spacing(2) : 0,
                    cursor: 'pointer',
                    position: 'relative',
                  }}
                  onClick={() => handleNavigation('/form-cg')}
                >
                  <Box
                    sx={{
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  ></Box>
                  Faire une demande de CG
                  <Typography
                    variant="body1"
                    color="black"
                    textAlign={'center'}
                    sx={{ marginTop: 1, fontSize: '14px', display: 'block', width: '100%' }}
                  >
                    10 € HT
                  </Typography>
                </Box>
              )}
              <Box
                className="linkBox"
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: isMobile ? theme.spacing(2) : 0,
                  cursor: 'pointer',
                  position: 'relative',
                  textDecoration: 'none',
                }}
                component="a"
                href="https://form.typeform.com/to/Xx5CeYaU"
                target="_blank"
                rel="noopener noreferrer"
              >
                Déclaration d'achat
              </Box>
              <Box
                className="linkBox"
                style={{
                  flexDirection: 'column',
                  justifyContent: 'center',
                  marginBottom: isMobile ? theme.spacing(2) : 0,
                  cursor: 'pointer',
                  position: 'relative',
                  textDecoration: 'none',
                }}
                component="a"
                href="https://skipcar.fr/connexion/professionnel"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Box
                  sx={{
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: '0px',
                      left: '-30px',
                      backgroundColor: '#FF6060',
                      transform: 'rotate(-45deg)',
                      padding: '0 0.5em',
                    }}
                  >
                    Nouveau
                  </Typography>
                </Box>
                Marketplace
              </Box>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
};

export default HomePage;
