import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Link,
} from '@mui/material';

const stickyCellStyle = {
  background: '#262626',
  fontWeight: 'bold',
  minWidth: 50,
  color: 'white',
  position: 'sticky',
  top: 0,
  zIndex: 100,
};

interface StockDetailsDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  data: Array<{
    pipedrive_deal_id: string;
    registration_plate: string;
    reg_plate: string;
    ext_url: string;
    days_in_stock: number;
    business_type: string;
  }>;
}

const StockDetailsDialog: React.FC<StockDetailsDialogProps> = ({ open, onClose, title, data }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Paper sx={{ marginTop: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={stickyCellStyle}>Plaque</TableCell>
                <TableCell sx={stickyCellStyle}>Pipedrive Deal Id</TableCell>
                <TableCell sx={stickyCellStyle}>Jours en stock</TableCell>
                <TableCell sx={stickyCellStyle}>Lien</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.registration_plate}</TableCell>
                  <TableCell>{item.pipedrive_deal_id}</TableCell>
                  <TableCell>{item.days_in_stock}</TableCell>
                  <TableCell>
                    <Link href={item.ext_url} target="_blank" rel="noopener noreferrer">
                      Voir la fiche
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default StockDetailsDialog;
