import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDailySellingStats } from '../../hooks/useApiQueries';

interface DailySellingStatsTableProps {
  franchise?: string;
  orgName?: string;
}

const DailySellingStatsTable: React.FC<DailySellingStatsTableProps> = ({ franchise, orgName }) => {
  const { data: stats, isLoading } = useDailySellingStats(franchise, orgName);

  if (isLoading || !stats) return null;

  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#262626' }}>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Montant net</TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Marge nette</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((stat) => (
            <TableRow key={stat.date}>
              <TableCell>{format(new Date(stat.date), 'dd MMM yyyy', { locale: fr })}</TableCell>
              <TableCell>{formatAmount(stat.net_amount)}</TableCell>
              <TableCell>{formatAmount(stat.net_margin)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DailySellingStatsTable;
