import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { useDailyBuyingStats } from '../../hooks/useApiQueries';
import theme from '../../theme';

interface DailyBuyingStatsTableProps {
  franchise?: string;
  orgName?: string;
}

interface DailyStats {
  date: string;
  quotations: number;
  purchases: number;
  transformation_rate: number | string;
}

const DailyBuyingStatsTable: React.FC<DailyBuyingStatsTableProps> = ({ franchise, orgName }) => {
  const { data: stats, isLoading } = useDailyBuyingStats(franchise, orgName);

  if (isLoading || !stats) return null;

  const formatTransformationRate = (rate: number | string): string => {
    const numRate = typeof rate === 'string' ? parseFloat(rate) : rate;
    return isNaN(numRate) ? '0.0' : numRate.toFixed(1);
  };

  const getTransformationColor = (rate: number | string): string => {
    const numRate = typeof rate === 'string' ? parseFloat(rate) : rate;
    return numRate >= 30 ? theme.palette.warning.main : theme.palette.error.main;
  };

  return (
    <TableContainer component={Paper} sx={{ marginTop: 2 }}>
      <Table>
        <TableHead>
          <TableRow sx={{ background: '#262626' }}>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Date</TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Cotations</TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Achats</TableCell>
            <TableCell sx={{ fontWeight: 600, color: 'white' }}>Taux de transfo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((stat: DailyStats) => (
            <TableRow key={stat.date}>
              <TableCell>{format(new Date(stat.date), 'dd MMM yyyy', { locale: fr })}</TableCell>
              <TableCell>{stat.quotations} cotations pour </TableCell>
              <TableCell>{stat.purchases} achats</TableCell>
              <TableCell>
                <Typography
                  component="span"
                  sx={{
                    color: getTransformationColor(stat.transformation_rate),
                    fontWeight: 'bold',
                  }}
                >
                  {formatTransformationRate(stat.transformation_rate)}%
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DailyBuyingStatsTable;
